import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.observeClasslistChange();
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  observeClasslistChange() {
    this.observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        this.dispatchChangedEvent()
      });
    });
    this.observer.observe(this.element, {
      attributes: true,
      attributeFilter: ['class'],
    });
  }

  dispatchChangedEvent(state) {
    const event = new CustomEvent('classlist:changed', {
      detail: {state: state},
      bubbles: false,
    });
    this.element.dispatchEvent(event);
  }
}
