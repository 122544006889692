export const BaseMixin = (Base) =>
  class extends Base {
    connect() {
      this.saved = false;

      this.enableSaveOnSubmit();
      this.loadPrintessEditor();
    }

    async loadPrintessEditor() {
      const printessLoader = await import("https://editor.printess.com/printess-editor/loader.js");

      this.editor = await printessLoader.load({
        token: this.shopTokenValue,
        templateName: this.templateNameValue,
        container: this.containerTarget
      });
    }

    enableSaveOnSubmit() {
      this.element.addEventListener("submit", (event) => {
        this.saveAndSubmit(event);
      });
    }

    saveAndSubmit(event) {
      if (this.saved) {
        return true;
      }

      event.preventDefault();

      // ProductPersonalizations#update has different routes to follow based on params[:commit]
      if (event.submitter && event.submitter.name && event.submitter.name == "commit" && event.submitter.value) {
        var commit_input = this.element.querySelector("input[type=hidden][name=commit]");
        if (!commit_input) {
          commit_input = document.createElement("input");
          commit_input.name = "commit";
          commit_input.type = "hidden";
          commit_input.value = event.submitter.value;

          this.element.appendChild(commit_input);
        }
      }

      this.editor.api.save().then((saveToken) => {
        this.saveTokenInputTarget.value = saveToken;
        this.saved = true;
        this.element.submit();
      });

      return false;
    }
  };
