import { Controller } from "@hotwired/stimulus";
import { PublisherConnector } from "@chili-publish/publisher-connector";

Stimulus.register(
  "chili-publish",
  class extends Controller {
    static targets = ["frame"];

    static values = {
      // e.g. Object { hex: "#000000", hsl: "0,0%,0%", rgb: "0,0,0" }
      colorMain1: Object,
      colorMain2: Object,
      colorSub1: Object,
      colorSub2: Object,
      colorSub3: Object,

      // e.g. Object { id: "a27687b8-76ba-4a0c-a661-ae18b3b6e804", family: "Barlow Thin", style: "Regular" }
      fontMain1: Object,
      fontSub1: Object,
      fontSub2: Object
    }

    connect() {
      this.loadEditor();
      this.enableSaveOnSubmit();
      this.saved = false;

      this.assignRangeQueue = [];
    }

    loadEditor() {
      this.frameTarget.src = this.frameTarget.dataset.editorUrl;

      PublisherConnector.build(this.frameTarget).then((editor) => {
        this.editor = editor;
        this.editor.addListener("DocumentFullyLoaded", (_target) => {
          this.editorReady();
        });
      });
    }

    editorReady() {
      this.updateColors();
      this.updateFonts();
      this.updateTextVariables();
      this.updateImagePlaceholders();
      this.observeSelectedFrameChange();
      this.observeDocumentDirtyStateChanged();
    }

    updateColors() {
      [
        { name: 'main1', value: this.colorMain1Value },
        { name: 'main2', value: this.colorMain2Value },
        { name: 'sub1', value: this.colorSub1Value },
        { name: 'sub2', value: this.colorSub2Value },
        { name: 'sub3', value: this.colorSub3Value }
      ].forEach((color) => {
        if (color.value.rgb) {
          let [r, g, b] = color.value.rgb.split(',')

          this.editor.getObject(`document.colors[${color.name}]`).then((object) => {
            if (object) {
              this.editor.setProperty(`document.colors[${object.id}]`, "type", "RGB").then(() => {
                this.editor.setProperty(`document.colors[${object.id}]`, "r", r).then(() => {
                  this.editor.setProperty(`document.colors[${object.id}]`, "g", g).then(() => {
                    this.editor.setProperty(`document.colors[${object.id}]`, "b", b)
                  })
                })
              })
            }
          });
        }
      })
    }

    updateFonts() {
      [
        { name: 'main1', value: this.fontMain1Value, replaces: 'Maax Regular_Bold' },
        { name: 'sub1', value: this.fontSub1Value, replaces: 'Maax Regular_Regular' },
        { name: 'sub2', value: this.fontSub2Value, replaces: 'Minion Pro_Regular' }
      ].forEach((font) => {
        if (font.value.id) {
          this.editor.getObject(`document.fonts[${font.value.id}]`).then((fontObject) => {
            if (fontObject) {
              this.replaceFont(font);
            } else {
              this.editor.executeFunction('document.fonts', 'Add').then((newFontObject) => {
                this.editor.setProperty(`document.fonts[${newFontObject.id}]`, "family", font.value.family).then(() => {
                  this.editor.setProperty(`document.fonts[${newFontObject.id}]`, "style", font.value.style).then(() => {
                    this.editor.setProperty(`document.fonts[${newFontObject.id}]`, "name", `${font.value.family} ${font.value.style}`).then(() => {
                      this.editor.setProperty(`document.fonts[${newFontObject.id}]`, "id", font.value.id).then(() => {
                        this.replaceFont(font);
                      })
                    })
                  })
                })
              })
            }
          })
        }
      })
    }

    replaceFont(font) {
      this.editor.getObject('document.paragraphStyles').then((paragraphStyles) => {
        let count = paragraphStyles.count;

        for (var index = 0; index < count; index++) {
          this.editor.executeFunction('document.paragraphStyles', 'GetItem', index).then((paragraphStyle) => {
            if (paragraphStyle.HasFormat) {
              this.editor.getObject(`document.paragraphStyles[${paragraphStyle.id}].format`).then((format) => {
                if (format.fontFamily == font.replaces) {
                  this.editor.setProperty(
                    `document.paragraphStyles[${paragraphStyle.id}].textFormat`,
                    "font",
                    `cp_object:document.fonts[${font.value.id}]`
                  );
                }
              })
            }
          });
        }
      });
    }

    updateTextVariables() {
      this.element
        .querySelectorAll('*[data-action*="pushTextVariable"]')
        .forEach((textVariableInput) => {
          // Pull text variables from Chili into text fields
          // this.editor
          //   .getObject(
          //     `document.variables[${textVariableInput.dataset.chiliPublishTargetParam}]`
          //   )
          //   .then((object) => {
          //     textVariableInput.value = object.value;
          //   });

          // Push text variables from text fields into Chili
          this.editor.setProperty(
            `document.variables[${textVariableInput.dataset.chiliPublishTargetParam}]`,
            "value",
            textVariableInput.value
          );
        });
    }

    updateImagePlaceholders() {
      // update all placeholders that have a value set on our side and don't have a remote url set in Chili Publisher
      // input[data-action="change->chili-publish#pushImagePlaceholder"]
      let placeholdersNodelist = this.element.querySelectorAll('input[data-action="change->chili-publish#pushImagePlaceholder"][value]');
      let placeholders = [...placeholdersNodelist];

      placeholders.forEach(placeholder => {
        this.editor
          .getObject(
            `document.allFrames[${placeholder.dataset.chiliPublishTargetParam}]`
          )
          .then((object) => {
            if (!/rest-api\/v1.2\/resources\/External\/download/.test(object.previewURL)) {
              var params = {};
              let placeholder_attributes = placeholder.attributes;

              [...placeholder_attributes].forEach(param => {
                let param_match = param.name.match(/^data-chili-publish-(.*)-param$/);
                if (param_match) {
                  params[this.camelize(param_match[1])] = this.typecast(param.value);
                }

                if (param.name == 'data-chili-publish-current-placeholder') {
                  params['placeholder'] = JSON.parse(param.value).data;
                }
              })

              this.pushImagePlaceholderValue(placeholder, params);
            }
          });
      });
    }

    observeSelectedFrameChange() {
      this.editor.addListener("SelectedFrameChanged", (selectedFrameId) => {
        if (selectedFrameId) {
          this.editor
            .getObject(`document.allFrames[${selectedFrameId}]`)
            .then((frame) => {
              if (frame.TextSpan) {
                frame.TextSpan.split(/(%.*?%)/)
                  .filter((s) => /^%.*%$/.test(s))
                  .forEach((textVariable) => {
                    textVariable = textVariable.replace(/^%(.*?)%$/, "$1");
                    this.element
                      .querySelectorAll(
                        `*[data-chili-publish-target-param="${textVariable}"]`
                      )
                      .forEach((textVariableInput) => {
                        textVariableInput.focus();
                      });
                  });
              } else {
                this.element
                  .querySelectorAll(
                    `*[data-chili-publish-target-param="${frame.id}"]`
                  )
                  .forEach((input) => {
                    if (
                      input.classList.contains("image-selection-placeholder")
                    ) {
                      let select_button = input.parentElement.querySelector(".select-or-upload-btn") ||
                        input.parentElement.querySelector(".select-btn");

                      // a click is bubbled through to the body and immediatly closes the dialog again
                      // select_button.click();
                      $(select_button.dataset.target).modal("show");
                    }
                  });
              }
            });
        }
      });
    }

    pushTextVariable(event) {
      this.editor.setProperty(
        `document.variables[${event.params.target}]`,
        "value",
        event.target.value
      );
    }

    pushImagePlaceholder(event) {
      let placeholder = event.target;
      let params = event.params;

      this.pushImagePlaceholderValue(placeholder, params);
    }

    pushImagePlaceholderValue(placeholder, params) {
      let target = params.target;
      let value = placeholder.value;

      if (params.rangeSelect) {
        let queueItem = {
          placeholder: placeholder,
          params: params
        }

        if (this.assignRangeQueue.filter(queuedItem => queuedItem.placeholder.id == queueItem.placeholder.id).length == 0) {
          this.assignRangeQueue[this.assignRangeQueue.length] = queueItem;
          this.processAssignRangeQueue();
        }
      } else if (params.placeholder) {
        let placeholder = params.placeholder;
        let url = this.populateUrlParams(params.url, placeholder, value);
        let thumbnailUrl = this.populateUrlParams(
          params.thumbnailUrl,
          placeholder,
          value
        );

        this.applyImagePlaceholderValue({
          valuePresent: !!value,
          valueId: value,
          target: target,

          url: url,
          thumbnailUrl: thumbnailUrl,

          width: placeholder.width,
          height: placeholder.height,
          fileSize: placeholder.fileSize
        });
      }
    }

    processAssignRangeQueue() {
      if (this.pushImagePlaceholderRangeValueRunning) {
        return;
      }

      let nextItem = this.assignRangeQueue.pop();

      if (nextItem) {
        this.pushImagePlaceholderRangeValue(nextItem.placeholder, nextItem.params);
      } else {
        this.pushImagePlaceholderRangeValueRunning = false;
      }
    }

    pushImagePlaceholderRangeValue(placeholder, params) {
      this.pushImagePlaceholderRangeValueRunning = true;
      let target = params.target;
      let value = placeholder.value;

      fetch(
        placeholder.dataset.chiliPublishRangeAssignPath,
        {
          method: 'PATCH',
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
          body: JSON.stringify({
            authenticity_token: this.element.querySelector('input[name="authenticity_token"]').value,
            value: value,
          }),
        }
      ).then((response) => response.json())
        .then((json) => {
          let file = json.files[0];

          if (!file) {
            this.applyImagePlaceholderValue({
              valuePresent: false,
              target: target
            });

            this.pushImagePlaceholderRangeValueRunning = false;
            this.processAssignRangeQueue();
            return
          }

          let placeholder = file.image_library_link_attributes.data;
          let url = this.populateUrlParams(params.url, placeholder, file.name);
          let thumbnailUrl = this.populateUrlParams(
            params.thumbnailUrl,
            placeholder,
            file.name
          );

          this.applyImagePlaceholderValue({
            valuePresent: true,
            valueId: file.name,
            target: target,

            url: url,
            thumbnailUrl: thumbnailUrl,

            width: placeholder.width,
            height: placeholder.height,
            fileSize: placeholder.file_size
          });

          this.pushImagePlaceholderRangeValueRunning = false;
          this.processAssignRangeQueue();
        });
    }

    applyImagePlaceholderValue({ valuePresent, valueId, target, url, thumbnailUrl, width, height, fileSize }) {
      if (valuePresent) {
        valueId = valueId.replace(/^\//, '') // /logo.pdf -> logo.pdf

        var assetDefinitionXML = `
            <item id="${valueId}"
                  name="${valueId}"
                  remoteURL="${thumbnailUrl}"
                  thumb="${thumbnailUrl}"
                  highResPdfURL="${url}"
                  keepExternal="true"
                  accessibleFromClient="false">
              <fileInfo width="${width}" height="${height}" resolution="300" fileSize="${fileSize || 100}" />
            </item>
          `;

        this.editor.executeFunction(
          `document.allFrames[${target}]`,
          "LoadContentFromExternalServerXmlString",
          assetDefinitionXML
        );
      } else {
        this.editor.executeFunction(
          `document.allFrames[${target}]`,
          "ClearContent"
        );
      }
    }

    populateUrlParams(url, placeholder, value) {
      return url
        .replaceAll("/:s/", `/${encodeURIComponent(placeholder.secret)}/`)
        .replaceAll("/:path", `/${encodeURIComponent(value)}`);
    }

    setAlternateLayout({ params: { target } }) {
      this.editor.setProperty(
        `document.variables[SetAlternateLayout]`,
        "value",
        target
      );
    }

    enableSaveOnSubmit() {
      this.element.addEventListener("submit", (event) => {
        this.saveAndSubmit(event);
      });
    }

    saveAndSubmit(event) {
      if (this.saved) {
        return true;
      }

      event.preventDefault();

      // ProductPersonalizations#update has different routes to follow based on params[:commit]
      if (event.submitter && event.submitter.name && event.submitter.name == "commit" && event.submitter.value) {
        var commit_input = this.element.querySelector("input[type=hidden][name=commit]");
        if (!commit_input) {
          commit_input = document.createElement("input");
          commit_input.name = "commit";
          commit_input.type = "hidden";
          commit_input.value = event.submitter.value;

          this.element.appendChild(commit_input);
        }
      }

      this.editor.addListener("DocumentSaved", () => {
        this.saved = true;
        this.element.submit();
      })

      this.editor.executeFunction("document", "Save")

      return false;
    }

    observeDocumentDirtyStateChanged() {
      this.beforeUnloadListener = (event) => {
        // TwitterBootstrapConfirm changes $.rails.allowAction to window.allow_action_true when the action
        // has been confirmed. We don't need a second confirmation
        if ($.rails.allowAction == window.allow_action_true) { return; }

        event.preventDefault();
        const confirmationMessage = "\\o/"; // note: browsers do not display this message anymore

        // Gecko + IE
        (event || window.event).returnValue = confirmationMessage;

        // Safari, Chrome, and other WebKit-derived browsers
        return confirmationMessage;
      };

      this.editor.addListener("DocumentDirtyStateChanged", (_target) => {
        this.documentDirtyStateChanged();
      });

      this.element.addEventListener("submit", () => {
        // A submit does not require confirmation
        $.rails.allowAction = window.allow_action_true;
      });
    }

    documentDirtyStateChanged() {
      this.editor.getDirtyState().then((hasUnsavedChanges) => {
        if (hasUnsavedChanges) {
          window.addEventListener("beforeunload", this.beforeUnloadListener, { capture: true });
        } else {
          window.removeEventListener("beforeunload", this.beforeUnloadListener, {
            capture: true,
          });
        }
      });
    }

    // Taken from https://github.com/hotwired/stimulus/blob/8cbca6db3b1b2ddb384deb3dd98397d3609d25a0/src/core/action.ts
    // because we need to construct event params in updateImagePlaceholders
    camelize(value) {
      return value.replace(/(?:[_-])([a-z0-9])/g, (_, char) => char.toUpperCase());
    }

    typecast(value) {
      try {
        return JSON.parse(value);
      } catch (o_O) {
        return value;
      }
    }
  }
);
