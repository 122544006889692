import { typograph_document } from "../../../vendor/typograph/viewer";

export const BaseMixin = (Base) =>
  class extends Base {
    connect() {
      this.setTextVariableValueAbortController = null;
      this.setTextVariableValuePID = null;
      this.updatePriceListAbortController = null;
      this.updatePriceListPID = null;

      this.loadEditor();
      this.enableSaveOnSubmit();

      this.element.addEventListener('classlist:changed', this.setDimensions.bind(this));
      this.assignRangeQueue = [];
    }

    disconnect() {
      this.element.removeEventListener('classlist:changed', this.setDimensions.bind(this));
    }

    setDimensions(_event) {
      // make sure we reduce our canvas element ...
      this.canvasElement.style.width = "100px";
      this.canvasElement.style.height = "100px";

      /// ... before we set the frame to whatever is available for us
      this.canvasElement.style.width = this.frameTarget.clientWidth + "px";
      this.canvasElement.style.height = this.frameTarget.clientHeight + "px";

      // notify Typograph about this change, so it can redraw
      if (this.typograph_document) {
        this.typograph_document.resize_main_canvas();
      }
    }

    loadEditor() {
      this.canvasElement = document.createElement("canvas");
      this.canvasElement.id = this.canvasIdValue;
      this.setDimensions()

      this.canvasElement.addEventListener("typograph_ready", (event) => {
        this.editorReady(event);
      });

      this.canvasElement.addEventListener("typograph_document_opened", (event) => {
        this.typographDocumentOpened(event);
      });

      this.canvasElement.addEventListener("typograph_page_images_loaded", (event) => {
        this.typographPageImagesLoaded(event);
      });

      this.canvasElement.addEventListener("typograph_textInput", (event) => {
        const textVariableInput = this.element.querySelector(`[data-typograph-target-param*="${event.detail.uuid}"]`);

        if (textVariableInput && textVariableInput.value !== event.detail.text) {
          textVariableInput.value = event.detail.text;

          // also push it back to Typograph just in case this variable has multiple UUID's
          this.setTextVariableValue(textVariableInput.dataset.typographTargetParam, textVariableInput.value);
        } else {
          console.warn(`No element found with data-typograph-target-param="${event.detail.uuid}"`);
        }
      });

      this.frameTarget.appendChild(this.canvasElement);

      let setup = { canvas_id: this.canvasIdValue, document_url: "", user_id: "", viewer_only: true };
      this.typograph_document = new typograph_document(setup);
      window.g_typograph_document = this.typograph_document;
      this.typograph_document.setup_fontmanager();
      this.typograph_document.setup_documentmanager();
      this.typograph_document.setup_scollbars();
      this.typograph_document.setup_zoom();
      this.typograph_document.setup_undo_redo();
      this.typograph_document.setup_page_bar();
      this.typograph_document.setup_documentmousemanager();
      this.typograph_document.setup_menushortcuts();
      this.typograph_document.setup_undomanager();
      this.typograph_document.setup_dialogmanager("typograph_viewer");
    }

    editorReady() {
      this.typograph_document.open_document(JSON.parse(this.inputTarget.value));
    }

    typographDocumentOpened() {
      this.updateTextVariables();
      this.setPageVisibility();
      this.documentLoaded = true;

      if (this.updatePriceListAfterDocumentLoaded) {
        this.updatePriceList();
      }
    }

    typographPageImagesLoaded() {
      if (this.imagePlaceholdersApplied) { return; }

      this.imagePlaceholdersApplied = true;
      this.updateImagePlaceholders();
    }

    enableSaveOnSubmit() {
      this.element.addEventListener("submit", (event) => {
        this.saveAndSubmit(event);
      });
    }

    saveAndSubmit(_event) {
      this.inputTarget.value = this.typograph_document.get_document_data_as_string();

      return true;
    }

    camelize(value) {
      return value.replace(/(?:[_-])([a-z0-9])/g, (_, char) => char.toUpperCase());
    }

    typecast(value) {
      try {
        return JSON.parse(value);
      } catch (o_O) {
        return value;
      }
    }

  };
