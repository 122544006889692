// Typograph controller
//
// Used API (except for loadEditor):
//
// * typograph_document.get_document_data_as_string
// * typograph_document.open_document
// * typograph_document.pages
//   * page.getElementByUUID
//     * pageElement.add_template
//     * pageElement.auto_layout
//       * autoLayout.remove_clip
//       * autoLayout.templates
//       * autoLayout.clips
//         * clip.page_elements
//     * pageElement.set_text
//     * pageElement.set_image_scale
//     * pageElement.set_url
// * typograph_document.redraw
// * typograph_document.resize_main_canvas
// * typograph_document.set_active_page
// * typograph_document.set_visible_page_indexes
//
// Observed events:
//
// * typograph_document_opened
// * typograph_page_images_loaded
// * typograph_ready
// * typograph_textInput

import { Controller } from "@hotwired/stimulus";

import { composeMixins } from "./utils/compose_mixins";

import { BaseMixin } from "./mixins/typograph/base_mixin";
import { PageMixin } from "./mixins/typograph/page_mixin";
import { ImagePlaceholderMixin } from "./mixins/typograph/image_placeholder_mixin";
import { TextVariableMixin } from "./mixins/typograph/text_variable_mixin";
import { PriceListMixin } from "./mixins/typograph/price_list_mixin";

const ComposedController = composeMixins(
  Controller,
  BaseMixin,
  PageMixin,
  ImagePlaceholderMixin,
  TextVariableMixin,
  PriceListMixin
);

Stimulus.register(
  "typograph",
  class extends ComposedController {
    static targets = [
      "frame",
      "input",
      "editorData",
      "idmlPreferences",
      "priceListGroup",
      "priceListGroupAutoNumericInput",
      "priceListTemplateTable"
    ];

    static values = {
      canvasId: String,

      previewPrefix: String,
      pagePrefixes: Array,

      // e.g. Object { hex: "#000000", hsl: "0,0%,0%", rgb: "0,0,0" }
      colorMain1: Object,
      colorMain2: Object,
      colorSub1: Object,
      colorSub2: Object,
      colorSub3: Object,

      // e.g. Object { url: "https://..." }
      fontMain1: Object,
      fontSub1: Object,
      fontSub2: Object
    }
  }
);
