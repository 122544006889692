export const TextVariableMixin = (Base) =>
  class extends Base {
    updateTextVariables() {
      this.element
        .querySelectorAll('*[data-action*="pushTextVariable"]')
        .forEach((textVariableInput) => {
          this.setTextVariableValue(textVariableInput.dataset.typographTargetParam, textVariableInput.value);
        });
    }

    pushTextVariable(event) {
      this.setTextVariableValue(event.params.target, event.target.value);
    }

    async setTextVariableValue(target, value) {
      if (this.setTextVariableValueAbortController) { this.setTextVariableValueAbortController.abort(); }
      if (this.setTextVariableValuePID) { clearTimeout(this.setTextVariableValuePID); }

      const controller = new AbortController();
      this.setTextVariableValueAbortController = controller;

      this.setTextVariableValuePID = setTimeout(() => {
        try {
          if (controller.signal.aborted) return;

          let uuids = typeof (target) == 'string' ? JSON.parse(target) : target;

          uuids.forEach(uuid => {
            this.typograph_document.pages.forEach(page => {
              let pageElement = page.getElementByUUID(uuid);

              if (pageElement) { pageElement.set_text(value); }
              if (controller.signal.aborted) return;
            });
          });

          if (controller.signal.aborted) return;
          this.typograph_document.redraw();

          this.setTextVariableValuePID = null;
        } catch (error) {
          if (!controller.signal.aborted) { throw error; }
        } finally {
          if (this.setTextVariableValueAbortController === controller) {
            this.setTextVariableValueAbortController = null;
          }
        }
      }, 250);
    }
  };
