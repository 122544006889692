import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["checkbox", "form"]

  submitForm() {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    const formData = new FormData(this.formTarget);
    formData.append("authenticity_token", csrfToken);

    fetch(this.formTarget.action, {
      method: this.formTarget.method,
      headers: {
        'Accept': 'application/json',
      },
      body: formData
    })
    .then(response => response.json())
    .then(data => {
      if (data.success) {
      } else {
        console.error('Server returned an error:', data.message);
      }
    })
    .catch(error => console.error('There was a problem with the request:', error));
  }
}
