import { Controller } from "@hotwired/stimulus";

import { composeMixins } from "./utils/compose_mixins";

import { BaseMixin } from "./mixins/printess/base_mixin";

const ComposedController = composeMixins(
  Controller,
  BaseMixin
);

Stimulus.register(
  "printess",
  class extends ComposedController {
    static targets = [
      "container",
      "saveTokenInput"
    ];

    static values = {
      shopToken: String,
      templateName: String
    }
  }
);
