export const ImagePlaceholderMixin = (Base) =>
  class extends Base {
    updateImagePlaceholders() {
      // update all placeholders
      let placeholdersNodelist = this.element.querySelectorAll('input[data-action="change->typograph#pushImagePlaceholder"][value]');
      let placeholders = [...placeholdersNodelist];

      placeholders.forEach(placeholder => {
        let uuid = placeholder.attributes['data-typograph-target-param'].value;

        this.typograph_document.pages.forEach(page => {
          let page_element = page.getElementByUUID(uuid);
          if (page_element) {
            var params = {};
            let placeholder_attributes = placeholder.attributes;

            [...placeholder_attributes].forEach(param => {
              let param_match = param.name.match(/^data-typograph-(.*)-param$/);
              if (param_match) {
                params[this.camelize(param_match[1])] = this.typecast(param.value);
              }

              if (param.name == 'data-typograph-current-placeholder') {
                params['placeholder'] = JSON.parse(param.value).data;
              }
            })

            this.pushImagePlaceholderValue(placeholder, params);
          }
        });
      });
    }

    pushImagePlaceholder(event) {
      let placeholder = event.target;
      let params = event.params;

      this.pushImagePlaceholderValue(placeholder, params);
    }

    pushImagePlaceholderValue(placeholder, params) {
      let target = params.target;
      let value = placeholder.value;

      if (placeholder.dataset.processRange && params.rangeSelect) {
        let queueItem = {
          placeholder: placeholder,
          params: params
        }

        if (this.assignRangeQueue.filter(queuedItem => queuedItem.placeholder.id == queueItem.placeholder.id).length == 0) {
          this.assignRangeQueue[this.assignRangeQueue.length] = queueItem;
          this.processAssignRangeQueue();
        }
      } else if (params.placeholder) {
        let placeholder = params.placeholder;
        let url = this.populateUrlParams(params.url, placeholder, value);

        this.applyImagePlaceholderValue({
          valuePresent: !!value,
          target: target,
          url: url
        });
      }
    }

    processAssignRangeQueue() {
      if (this.pushImagePlaceholderRangeValueRunning) {
        return;
      }

      let nextItem = this.assignRangeQueue.pop();

      if (nextItem) {
        this.pushImagePlaceholderRangeValue(nextItem.placeholder, nextItem.params);
      } else {
        this.pushImagePlaceholderRangeValueRunning = false;
      }
    }

    pushImagePlaceholderRangeValue(placeholder, params) {
      this.pushImagePlaceholderRangeValueRunning = true;
      let target = params.target;
      let value = placeholder.value;

      fetch(
        placeholder.dataset.typographRangeAssignPath,
        {
          method: 'PATCH',
          headers: { 'Content-type': 'application/json; charset=UTF-8' },
          body: JSON.stringify({
            authenticity_token: this.element.querySelector('input[name="authenticity_token"]').value,
            value: value,
          }),
        }
      ).then((response) => response.json())
        .then((json) => {
          let file = json.files[0];

          if (!file) {
            this.applyImagePlaceholderValue({
              valuePresent: false,
              target: target
            });

            this.pushImagePlaceholderRangeValueRunning = false;
            this.processAssignRangeQueue();
            return
          }

          let placeholder = file.image_library_link_attributes.data;
          let url = this.populateUrlParams(params.url, placeholder, file.name);

          this.applyImagePlaceholderValue({
            valuePresent: true,
            target: target,
            url: url
          });

          this.pushImagePlaceholderRangeValueRunning = false;
          this.processAssignRangeQueue();
        });
    }

    applyImagePlaceholderValue({ valuePresent, target, url }) {
      const urlObj = new URL(url, window.location.origin);
      urlObj.searchParams.set('v', Date.now());
      url = urlObj.toString();

      let uuids = typeof (target) == 'string' ? JSON.parse(target) : target;

      uuids.forEach(uuid => {
        this.typograph_document.pages.forEach(page => {
          let pageElement = page.getElementByUUID(uuid);
          if (pageElement) {
            if (valuePresent) {
              pageElement.hidden = false;
              pageElement.set_image_scale('ScaleToFit');
              pageElement.set_url(url);
            } else {
              pageElement.hidden = true;
              this.typograph_document.redraw();
            }
          }
        });
      });
    }

    populateUrlParams(url, placeholder, value) {
      return url
        .replaceAll("/:s/", `/${encodeURIComponent(placeholder.secret)}/`)
        .replaceAll("/:path", `/${encodeURIComponent(value)}`);
    }
  };
