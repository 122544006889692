export const PageMixin = (Base) =>
  class extends Base {
    setPageVisibility() {
      let visiblePageIndices = this.prefixPageIndices(this.previewPrefixValue)

      if (visiblePageIndices.length > 0) {
        this.typograph_document.set_visible_page_indexes(visiblePageIndices)
        this.typograph_document.set_active_page(visiblePageIndices[0])
      }
    }

    prefixPageIndices(targetPrefix) {
      return this.pagePrefixesValue
        .filter(item => item.prefix === targetPrefix)
        .map(item => item.page_index);
    }

    setAlternateLayout({ params: { target } }) {
      this.previewPrefixValue = target;
      this.setPageVisibility();
    }
  };
