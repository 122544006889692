export const PriceListMixin = (Base) =>
  class extends Base {
    updatePriceList(_) {
      if (!this.documentLoaded) {
        this.updatePriceListAfterDocumentLoaded = true;
        return
      }

      this.refreshPriceLists();
    }

    async refreshPriceLists() {
      if (this.updatePriceListAbortController) { this.updatePriceListAbortController.abort(); }
      if (this.updatePriceListPID) { clearTimeout(this.updatePriceListPID); }

      const controller = new AbortController();
      this.updatePriceListAbortController = controller;

      this.updatePriceListPID = setTimeout(() => {
        try {
          if (controller.signal.aborted) return;

          const priceListPersistEvent = new CustomEvent('price_list:persist');
          this.priceListTemplateTableTarget.dispatchEvent(priceListPersistEvent);

          let editorData = JSON.parse(this.editorDataTarget.value);
          const recordsPerFrame = Map.groupBy(editorData.records, (record) => (record.textframe));

          recordsPerFrame.forEach((records, uuid) => {
            this.refreshPriceList(uuid, editorData, records);
          });

          this.updatePriceListPID = null;
        } catch (error) {
          if (!controller.signal.aborted) { throw error; }
        } finally {
          if (this.updatePriceListAbortController === controller) {
            this.updatePriceListAbortController = null;
          }
        }
      }, 250);
    }

    refreshPriceList(uuid, editorData, records) {
      if (this.updatePriceListAbortController.signal.aborted) return;

      this.typograph_document.pages.forEach(page => {
        let pageElement = page.getElementByUUID(uuid);

        if (pageElement) { this.refreshPriceListElement(pageElement, editorData, records); }

        if (this.updatePriceListAbortController.signal.aborted) return;
      });
    }

    refreshPriceListElement(pageElement, editorData, records) {
      this.clearPriceListFrame(pageElement);
      this.addPriceListRecords(pageElement, editorData, records);
    }

    clearPriceListFrame(pageElement) {
      while (pageElement.auto_layout.clips.length > 0) {
        pageElement.auto_layout.remove_clip(pageElement, 0);

        if (this.updatePriceListAbortController.signal.aborted) return;
      }
    }

    addPriceListRecords(pageElement, editorData, records) {
      records.forEach((record, index) => {
        let previousRecord = records[index - 1];

        this.addPriceListRecord(pageElement, editorData.field_tree, record, previousRecord);

        if (this.updatePriceListAbortController.signal.aborted) return;
      });
    }

    // Adds a price list record to a pageElement
    //
    // A record is a single row that also contains tree-ish information. See ProductPersonalizationTemplateService for
    // details about the structure
    addPriceListRecord(pageElement, fieldTree, record, previousRecord) {

      if (fieldTree.children) {
        // this scenario hasn't been developed yet...

      } else {
        let templateIndex = pageElement.auto_layout.templates.findIndex(obj => obj.name === record.properties.Template)
        if (templateIndex < 0) { return }

        // Typograph todo: add_template does not return the clip. These lines can be merged if it does
        // let clip = pageElement.add_template(templateIndex);
        pageElement.add_template(templateIndex);
        let clip = pageElement.auto_layout.clips[pageElement.auto_layout.clips.length - 1];

        fieldTree.fields.forEach(fieldIndex => {
          let fieldName = record.properties[`Template[${fieldIndex}]`];

          // Typograph todo: add a getElementByName on clip
          // Typograph todo: don't add an index to object names
          // let fieldElement = clip.getElementByName(fieldName)
          const fieldElementPattern = new RegExp(`^${fieldName}(_\\d+)?$`);
          let fieldElementIndex = clip.page_elements.findIndex(obj => fieldElementPattern.test(obj.name));
          let fieldElement = clip.page_elements[fieldElementIndex];

          if (fieldElement) {
            fieldElement.set_text(record.data[fieldIndex]);
          }
        });
      }
    }

    priceListGroupTargetConnected(_element) {
      this.updatePriceList();
    }

    priceListGroupTargetDisconnected(_element) {
      this.updatePriceList();
    }

    // Omdat autoNumeric ons geen normaal input event suurt
    priceListGroupAutoNumericInputTargetConnected(element) {
      jQuery(element).on('change', this.priceListGroupAutoNumericInputChanged);
    }

    priceListGroupAutoNumericInputTargetDisconnected(element) {
      jQuery(element).off('change', this.priceListGroupAutoNumericInputChanged);
    }

    priceListGroupAutoNumericInputChanged(_event) {
      this.dispatchEvent(new Event('input'));
    }
  };
