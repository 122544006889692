import { Controller } from "@hotwired/stimulus";

Stimulus.register(
  "product-personalization-image-placeholder-image-library-select-dialog",
  class extends Controller {
    static targets = ["modalBody"];

    static values = {
      sourcePath: String,
      styleId: String,
    };

    connect() {
      $(this.element).on("shown.bs.modal", () => {
        this.modalShown();
      });
    }

    modalShown() {
      if (this.loaded) {
        return;
      }
      this.loaded = true;

      let path = this.sourcePathValue + (this.sourcePathValue.match(/\?/) ? '&' : '?') + "style_id=" + this.styleIdValue;

      fetch(path).then((response) => {
        response.text().then((body) => {
          this.modalBodyTarget.innerHTML = body;

          window.load_image_placeholder(this.element);
        });
      });
    }

    reload() {
      this.loaded = false;
    }
  }
);
