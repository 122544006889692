import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    spinner: String
  }

  update(event) {
    event.currentTarget.innerHTML = this.spinnerValue;

    let path = event.params.path;

    let params = {
      object_name: event.params.objectName,
      row_index: event.params.rowIndex,
      column_index: event.params.columnIndex,
      direction: event.params.direction
    };

    this.element.querySelectorAll('input, select, textarea').forEach((input) => {
      if (!input.disabled) {
        params[input.name] = input.value;
      }
    });

    $.ajax({
      url: path,
      dataType: 'json',
      method: 'POST',
      data: params,
      success: (response) => {
        this.element.innerHTML = response.html;
      },
      error: (o_0) => {
        // Just reload...
        Turbolinks.visit(window.location)
      }
    });
  }
}
